#page{
    z-index:1;
    margin-left:auto;
    background-color:transparent;
    background-image:none;
    border-color:#000000;
    margin-right:auto;
    border-width:0px;
    width:100%;
}
.imprint #ptop{
    margin-right:-10000px;
    width:0.01px;
    margin-top:-18px;
}
#u117316{
    z-index:2;
    opacity:1;
    min-height:752.75px;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    padding-bottom:0px;
    filter:alpha(opacity=100);
    background:#12171A url("/img/gray_diagonal_stripes_seamless_background_pattern.png") repeat center center;
}
#u117316_align_to_page{
    margin-right:auto;
    margin-left:auto;
    padding-bottom:60px;
    padding-top:46px;
    position:relative;
}
#u117315-4{
    z-index:3;
    background-color:transparent;
    line-height:60px;
    border-color:#E3E3E3;
    position:relative;
    min-height:122px;
}
#u117314-24{
    z-index:7;
    background-color:transparent;
    margin-top:4px;
    position:relative;
    min-height:520px;
}
#u117314-2{
    line-height:28px;
    font-size:20px;
}
#u117314-4,#u117314-5,#u117314-7,#u117314-9,#u117314-10,#u117314-12,#u117314-14,#u117314-15,#u117314-17,#u117314-19,#u117314-20,#u117314-22{
    line-height:22px;
    color:#FFFFFF;
}
#u117316-bw{
    z-index:2;
    min-height:752.75px;
    margin-top:100px;
}
#top,body{
    position:relative;
}
@media (min-width: 769px), print{
    #page{
        min-height:500px;
        max-width:1200px;
    }
    #ptop{
        z-index:97;
    }
    #u117316_align_to_page{
        max-width:1200px;
    }
    #u117315-4{
        width:90%;
        left:5%;
    }
    #u117314-24{
        width:26.67%;
        left:36.67%;
    }
    body{
        padding-bottom:524px;
    }
}
@media (max-width: 768px){
    #page{
        min-height:802px;
        max-width:768px;
    }
    #ptop{
        z-index:104;
    }
    #u117316_align_to_page{
        max-width:768px;
    }
    #u117315-4{
        width:89.98%;
        left:4.95%;
    }
    #u117314-24{
        width:26.7%;
        left:36.72%;
    }
    body{
        overflow-x:hidden;
        min-width:320px;
        padding-bottom:650px;
    }
}


.breakpoint.imprint {
    display: block !important;
}