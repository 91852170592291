#page{
    z-index:1;
    margin-left:auto;
    background-color:transparent;
    background-image:none;
    border-color:#000000;
    margin-right:auto;
    border-width:0px;
    width:100%;
}
.terms #ptop{
    margin-right:-10000px;
    width:0.01px;
    margin-top:-18px;
}
#u117346{
    z-index:2;
    opacity:1;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    padding-bottom:0px;
    filter:alpha(opacity=100);
    background:#12171A url("/img/gray_diagonal_stripes_seamless_background_pattern.png?crc=4032283386") repeat center center;
}
#u117346_align_to_page{
    margin-right:auto;
    margin-left:auto;
    padding-bottom:51px;
    padding-top:46px;
    position:relative;
}
#u117345-4{
    z-index:3;
    background-color:transparent;
    line-height:60px;
    border-color:#E3E3E3;
    position:relative;
    min-height:122px;
}
#u117344-86{
    z-index:7;
    background-color:transparent;
    line-height:22px;
    margin-top:4px;
    position:relative;
    min-height:564px;
}
#u117344-4,#u117344-5,#u117344-9,#u117344-10,#u117344-14,#u117344-15,#u117344-19,#u117344-20,#u117344-24,#u117344-25,#u117344-29,#u117344-30,#u117344-34,#u117344-35,#u117344-39,#u117344-40,#u117344-44,#u117344-45,#u117344-49,#u117344-50,#u117344-54,#u117344-55,#u117344-59,#u117344-60,#u117344-64,#u117344-65,#u117344-69,#u117344-70,#u117344-74,#u117344-75,#u117344-79,#u117344-80,#u117344-84{
    color:#FFFFFF;
}
#u117346-bw{
    z-index:2;
    margin-top:100px;
}
#top,body{
    position:relative;
}
@media (min-width: 769px), print{
    #page{
        min-height:500px;
        max-width:1200px;
    }
    #ptop{
        z-index:159;
    }
    #u117346_align_to_page{
        max-width:1200px;
    }
    #u117345-4,#u117344-86{
        width:90%;
        left:5%;
    }
    #u117346,#u117346-bw{
        min-height:3083.75px;
    }
    body{
        padding-bottom:524px;
    }
}
@media (max-width: 768px){
    #page{
        min-height:802px;
        max-width:768px;
    }
    #ptop{
        z-index:166;
    }
    #u117346_align_to_page{
        max-width:768px;
    }
    #u117345-4,#u117344-86{
        width:89.98%;
        left:4.95%;
    }
    #u117346,#u117346-bw{
        min-height:4117.75px;
    }
    body{
        overflow-x:hidden;
        min-width:320px;
        padding-bottom:650px;
    }
}


.breakpoint.terms {
    display: block !important;
}